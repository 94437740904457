import { CategoryCard } from '../../../entities/Categories'
import { CategoryItem } from '../../../entities/Categories/model/types/CategoriessSchema'
import { Row, Col } from 'antd'

interface Props {
    items?: CategoryItem[]
    className?: string
}
const CategoriesBlocks = (props: Props) => {
    const { items, className } = props

    return (
        <Row justify={'space-between'} gutter={[10, 16]} className={className}>
            {items?.map((category) => (
                <Col
                    xs={12}
                    sm={11}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    key={category.id}
                >
                    <CategoryCard
                        categoryId={category.id}
                        price={`от ${
                            category.price_from ? category.price_from : ''
                        } сом`}
                        imageUrl={category?.image}
                        name={category.name_ru}
                    />
                </Col>
            ))}
        </Row>
    )
}

export default CategoriesBlocks
