import { DeleteOutlined } from '@ant-design/icons'
import {
    Button,
    Checkbox,
    ColorPicker,
    Divider,
    Form,
    FormProps,
    Input,
    Select,
    Typography
} from 'antd'
import { useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useSelector } from 'react-redux'
import {
    ProductsPanelActions,
    getEditingProductData,
    getProductsPanelAddData
} from '../..'
import useApiRequest from '../../../../../shared/lib/hooks/useApiRequest'
import { useAppDispatch } from '../../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { MyText } from '../../../../../shared/ui/MyText/MyText'
import { getCategoryList } from '../../../CategoriesPanel'
import { fetchCategoriesListService } from '../../../CategoriesPanel/model/services'
import {
    addProductService,
    fetchProductsData,
    productEditService
} from '../../model/services'
import { AddProductItem } from '../../model/types/ProductsPanelSchema'
import cls from './AddProduct.module.scss'

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            [{ font: [] }],
            [{ color: [] }],
            [{ size: ['small', false, 'large', 'huge'] }]
        ]
    }
}

export const AddProduct = () => {
    const dispatch = useAppDispatch()
    const { data, error, isLoading } = useSelector(getCategoryList)
    const [form] = Form.useForm()
    const categories = data?.items

    const { error: addError, isLoading: AddLoading } = useSelector(
        getProductsPanelAddData
    )

    const { EditingProduct, isEditing } = useSelector(getEditingProductData)

    const onFinishEditing = () => {
        dispatch(ProductsPanelActions.setEditingProductItemEnd())
        dispatch(
            fetchProductsData({
                page: 1,
                per_page: 100
            })
        )
    }
    const onCancelEditing = () => {
        dispatch(ProductsPanelActions.setEditingProductItemEnd())
    }
    const {
        fetchData: editProduct,
        isLoading: editLoading,
        error: editError
    } = useApiRequest(productEditService, 'Успешно измененно!', onFinishEditing)

    const onFinish: FormProps<AddProductItem>['onFinish'] = (values) => {
        if (isEditing) {
            editProduct(EditingProduct.id, values)
        } else {
            dispatch(addProductService(values))
        }
    }

    const onFinishFailed: FormProps<AddProductItem>['onFinishFailed'] = (
        errorInfo
    ) => {
        console.log('Failed:', errorInfo)
    }

    useEffect(() => {
        if (!data)
            dispatch(
                fetchCategoriesListService({
                    page: 1,
                    per_page: 100
                })
            )

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isEditing) {
            form.setFieldsValue({
                name_ru: EditingProduct.name_ru,
                name_kg: EditingProduct.name_kg,
                name_en: EditingProduct.name_en,
                category: EditingProduct.category.id,
                description: EditingProduct.description,
                recommended: EditingProduct.recommended,
                bestsellers: EditingProduct.bestsellers,
                promotional: EditingProduct.promotional,
                details: EditingProduct.details,
                code_name: EditingProduct.code_name,
                characteristics: EditingProduct.characteristics,
                recommended_left_side: EditingProduct.recommended_left_side
            })
        } else {
            form.resetFields()
        }
        //eslint-disable-next-line
    }, [EditingProduct, isEditing])

    return (
        <div>
            <Form
                disabled={editLoading || AddLoading}
                size="small"
                layout="horizontal"
                name="AddProductForm"
                form={form}
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{ maxWidth: 1000 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<AddProductItem>
                    label="Название"
                    name="name_ru"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<AddProductItem>
                    label="Название_kg"
                    name="name_kg"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<AddProductItem>
                    label="Название_en"
                    name="name_en"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<AddProductItem>
                    label="Код товара"
                    name="code_name"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<AddProductItem> label="Описание" name="description">
                    <ReactQuill theme="snow" modules={modules} />
                </Form.Item>
                <Form.Item<AddProductItem>
                    label="Категория:"
                    name="category"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <Select loading={isLoading}>
                        {categories?.map((category) => (
                            <Select.Option
                                value={category.id}
                                key={category.id}
                            >
                                {category.name_ru}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item<AddProductItem>
                    label="Рекомендации"
                    name="recommended"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item<AddProductItem>
                    label="Рекомендации левый блок"
                    name="recommended_left_side"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item<AddProductItem>
                    label="Хиты продаж"
                    valuePropName="checked"
                    name="bestsellers"
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item<AddProductItem>
                    label="Акционные товары"
                    name="promotional"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
                <Typography.Title level={4}>Характеристики:</Typography.Title>
                <Form.List name="characteristics">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name }) => (
                                <div key={key} style={{ marginBottom: 8 }}>
                                    <Form.Item
                                        name={[name, 'title_ru']}
                                        label="Название"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Заполните данные'
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'title_en']}
                                        label="Название_en"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Заполните данные'
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'title_kg']}
                                        label="Название_kg"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Заполните данные'
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'text']}
                                        label="Описание"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Заполните данные'
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Button onClick={() => remove(name)}>
                                        Удалить
                                    </Button>
                                </div>
                            ))}
                            <Button type="dashed" onClick={() => add()} block>
                                Добавить характеристики
                            </Button>
                        </>
                    )}
                </Form.List>
                <Divider />
                <Typography.Title level={4}>Товары детально:</Typography.Title>
                <Form.List name="details">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name }) => (
                                <div key={key}>
                                    <Form.Item
                                        name={[name, 'size']}
                                        label="Размер"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите размер'
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'quantity']}
                                        label="Количество"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите количество'
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder="Количество"
                                            type="number"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'price']}
                                        label="Цена"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите цену'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Цена" />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Введите цену'
                                            }
                                        ]}
                                        name={[name, 'discount_price']}
                                        label="Цена со скидкой"
                                    >
                                        <Input placeholder="Цена со скидкой" />
                                    </Form.Item>
                                    <Form.List name={[name, 'color']}>
                                        {(
                                            colorFields,
                                            {
                                                add: addColor,
                                                remove: removeColor
                                            }
                                        ) => (
                                            <div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap'
                                                    }}
                                                >
                                                    {colorFields.map(
                                                        ({
                                                            key: colorKey,
                                                            name: colorName
                                                        }) => (
                                                            <div key={colorKey}>
                                                                <Form.Item
                                                                    name={[
                                                                        colorName
                                                                    ]}
                                                                    getValueFromEvent={(
                                                                        color
                                                                    ) => {
                                                                        return (
                                                                            '#' +
                                                                            color.toHex()
                                                                        )
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required:
                                                                                true,
                                                                            message:
                                                                                'Выберите цвет'
                                                                        }
                                                                    ]}
                                                                    style={{
                                                                        flex: 3
                                                                    }}
                                                                >
                                                                    <ColorPicker
                                                                        value={
                                                                            'toHex'
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                                <Button
                                                                    onClick={() =>
                                                                        removeColor(
                                                                            colorName
                                                                        )
                                                                    }
                                                                    icon={
                                                                        <DeleteOutlined />
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <Button
                                                    size="small"
                                                    type="link"
                                                    onClick={() => addColor()}
                                                >
                                                    Добавить цвет
                                                </Button>
                                            </div>
                                        )}
                                    </Form.List>
                                    <Divider />
                                    <Button
                                        onClick={() => remove(name)}
                                        type="default"
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white'
                                        }}
                                    >
                                        Удалить детали
                                    </Button>
                                    <Divider />
                                </div>
                            ))}
                            <Button
                                type="dashed"
                                onClick={() => {
                                    const lastItem =
                                        fields.length > 0
                                            ? form.getFieldValue([
                                                  'details',
                                                  fields.length - 1,
                                                  'color'
                                              ])
                                            : []
                                    add({ color: lastItem }) // Добавляем новые детали с копией цветов из последнего блока, если они есть
                                }}
                                block
                            >
                                Добавить детали
                            </Button>
                        </>
                    )}
                </Form.List>

                <Form.Item wrapperCol={{ span: 16 }} className={cls.submitBtn}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={AddLoading || editLoading}
                    >
                        {isEditing ? 'Изменить' : 'Сохранить'}
                    </Button>
                </Form.Item>
                <Button type="dashed" onClick={onCancelEditing}>
                    Отмена
                </Button>
            </Form>
            {addError && <MyText text={addError} variant="error" />}
            {editError && <MyText text={editError} variant="error" />}
            {error && <MyText text={error} variant="error" />}
        </div>
    )
}
