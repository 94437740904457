import { getEditingProductData, getProductsListData, getProductsPanelAddData } from "./model/selectors/getProductsPaneldata";
import { addProductService, productDeleteImageService, productDeleteService, productEditService, productUploadImageService } from "./model/services";
import { ProductsPanelActions, ProductsPanelReducer } from "./model/slices/ProductsPanelSlice";
import { ProductItemType } from "./model/types/Product";

import { AddProduct } from "./ui/AddProduct/AddProduct";

import ProductsPanel from "./ui/ProductsPanel/ProductsPanel";
export type { ProductsPanelSchema } from './model/types/ProductsPanelSchema';

export {
    ProductsPanel, ProductsPanelActions,
    ProductsPanelReducer,
}

export default AddProduct

// services 
export { addProductService, productUploadImageService, productDeleteService, productEditService, productDeleteImageService }


// selectors
export { getProductsPanelAddData, getProductsListData, getEditingProductData }


// types 
export type { ProductItemType }

