import { AxiosResponse } from 'axios'
import api from '../../../../shared/api/api'
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AddReviewsAndTipsProps, EditingReviewsTipsData, ReviewsAndTipsItem, ReviewsAndTipsResponse } from '../types/ReviewsAndTipsSchema';
import { PaginationGlobalType } from '../../../../shared/types';



export const fetchReviewsAndTipsData = createAsyncThunk<ReviewsAndTipsResponse, PaginationGlobalType, ThunkConfig<string>>(
    'reviewsAndTips/fetchReviewsAndTipsData',
    async ({ page, per_page }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<ReviewsAndTipsResponse>('/reviews_and_tips/', {

                params: {
                    page: page,
                    per_page: per_page
                }
            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)

export const fetchReviewsAndTipsForEdit = createAsyncThunk<EditingReviewsTipsData, number | string, ThunkConfig<string>>(
    'reviewsAndTips/fetchReviewsAndTipsForEdit',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<EditingReviewsTipsData>(`/reviews_and_tips/${id}/`);
            if (!response.data) {
                throw new Error();
            }

            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)
export const fetchReviewsAndTipsDetail = createAsyncThunk<ReviewsAndTipsItem, number | string, ThunkConfig<string>>(
    'reviewsAndTips/fetchReviewsAndTipsDetail',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<ReviewsAndTipsItem>(`/reviews_and_tips/${id}/`);
            if (!response.data) {
                throw new Error();
            }

            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)


export async function addReviewsAndTypesService(props: AddReviewsAndTipsProps): Promise<AxiosResponse<ReviewsAndTipsResponse>> {
    return api.post<ReviewsAndTipsResponse>('/reviews_and_tips/', props)
}
export async function editReviewsAndTypesService(props: EditingReviewsTipsData, id: string | number): Promise<AxiosResponse<ReviewsAndTipsResponse>> {
    return api.put<ReviewsAndTipsResponse>(`/reviews_and_tips/${id}/`, props)
}
export async function removeReviewsAndTypesService(id: string | number): Promise<AxiosResponse<ReviewsAndTipsResponse>> {
    return api.delete<ReviewsAndTipsResponse>(`/reviews_and_tips/${id}/`)
}




// work conditions dublicated services

export const fetchWorkConditionsData = createAsyncThunk<ReviewsAndTipsResponse, PaginationGlobalType, ThunkConfig<string>>(
    'reviewsAndTips/fetchWorkConditionsData',
    async ({ page, per_page }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<ReviewsAndTipsResponse>('/working_conditions/', {
                params: {
                    page: page,
                    per_page: per_page
                }
            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)

export const fetchWorkConditionsForEdit = createAsyncThunk<EditingReviewsTipsData, number | string, ThunkConfig<string>>(
    'reviewsAndTips/fetchWorkConditionsForEdit',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<EditingReviewsTipsData>(`/working_conditions/${id}/`);
            if (!response.data) {
                throw new Error();
            }

            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)



export async function addWorkConditionsService(props: AddReviewsAndTipsProps): Promise<AxiosResponse<ReviewsAndTipsResponse>> {
    return api.post<ReviewsAndTipsResponse>('/working_conditions/', props)
}
export async function editWorkConditionsService(props: EditingReviewsTipsData, id: string | number): Promise<AxiosResponse<ReviewsAndTipsResponse>> {
    return api.put<ReviewsAndTipsResponse>(`/working_conditions/${id}/`, props)
}


export async function removeWorkConditionService(id: string | number): Promise<AxiosResponse<ReviewsAndTipsResponse>> {
    return api.delete<ReviewsAndTipsResponse>(`/working_conditions/${id}/`)
}