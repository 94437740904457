import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { App } from './app/App'
import { ErrorBoundary } from './app/providers/ErrorBoundary'
import { StoreProvider } from './app/providers/StoreProvider'
import { ThemeProvider } from './app/providers/ThemeProvider'
import './app/styles/index.scss'
import './index.css'
import './shared/config/i18n/i18n'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <HashRouter>
        <StoreProvider>
            <ErrorBoundary>
                <ThemeProvider>
                    <App />
                </ThemeProvider>
            </ErrorBoundary>
        </StoreProvider>
    </HashRouter>
)
