import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ThunkConfig } from '../../../../../app/providers/StoreProvider';
import api from '../../../../../shared/api/api';
import { PaginationGlobalType } from '../../../../../shared/types';
import { ProductsDataType } from '../types/Product';
import { AddProductItem } from '../types/ProductsPanelSchema';



export const addProductService = createAsyncThunk<any, AddProductItem, ThunkConfig<string>>(
    'product/addProductService',
    async (data, thunkApi) => {
        const { extra, dispatch, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.post<any>('/product/', data);
            if (!response.data) {
                throw new Error();
            }
            dispatch(fetchProductsData({ page: 1, per_page: 100 }))
            return response.data;
        } catch (e: any) {
            return rejectWithValue('error');
        }
    },
)




export const fetchProductsData = createAsyncThunk<ProductsDataType, PaginationGlobalType, ThunkConfig<string>>(
    'product/fetchProductsData',
    async (data, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<ProductsDataType>('/product/', {
                params: {
                    page: data.page,
                    per_page: data.per_page
                }
            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('error');
        }
    },
)




export async function productUploadImageService(id: any, data: FormData): Promise<AxiosResponse<any>> {
    return api.post<any>(`/product/${id}/upload_images/`, data)
}
export async function productDeleteService(id: any): Promise<AxiosResponse<any>> {
    return api.delete<any>(`/product/${id}/`)
}
export async function productDeleteImageService(image_id: any): Promise<AxiosResponse<any>> {
    return api.delete<any>(`/product/${image_id}/delete_image/
    `)
}

export async function productEditService(id: any, data: AddProductItem): Promise<AxiosResponse<any>> {
    return api.put<any>(`/product/${id}/`, data)
}






































