import { USER_TOKEN_KEY, USER_REFRESH_TOKEN_KEY } from "../const/localstorage"

export function tokenAvailability() {
    return localStorage.getItem(USER_TOKEN_KEY)
}

export function refreshTokenAvailability() {
    return localStorage.getItem(USER_REFRESH_TOKEN_KEY)
}


