import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasketCountSchema } from '../types/BasketCountSchema';

const initialState: BasketCountSchema = {
    count: 0,
};

export const BasketCountSlice = createSlice({
    name: 'BasketCount',
    initialState,
    reducers: {
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload
        },
    },

});

export const { actions: BasketCountActions } = BasketCountSlice;
export const { reducer: BasketCountReducer } = BasketCountSlice;