import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CategoriesData } from '../types/CategoriessSchema';
import { PaginationGlobalType } from '../../../../shared/types';



export const fetchCategoriesData = createAsyncThunk<CategoriesData, PaginationGlobalType, ThunkConfig<string>>(
    'categories/fetchCategoriesData',
    async ({ page, per_page }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<CategoriesData>('/category/', {
                params: {
                    page: page,
                    per_page: per_page
                }
            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении категорий!');
        }
    },
)