import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { InputNumber, Skeleton } from 'antd'
import { Input } from 'antd-mobile'
import { useCallback, useState } from 'react'
import { Detail } from '../../model/types/ProductDetailCardSchema'
import cls from './ProductCount.module.scss'
import { BrowserView, MobileView } from 'react-device-detect'
import useDebounce from '../../../../shared/lib/hooks/useDebounce/useDebounce'

interface Props {
    priceItem?: string | number
    countItem?: string | number
    detail?: Detail
    isLoading?: boolean
}
export const ProductCount = (props: Props) => {
    const { detail, isLoading = true } = props

    const [count, setCount] = useState<number>(1)

    const handleChangeCount = useCallback((e: number | null) => {
        if (e) {
            setCount(e)
        }
    }, [])
    const handleChangeCountMobile = useCallback((e: string) => {
        setCount(Number(e))
    }, [])

    const debouncedCountChangeMobile = useDebounce(handleChangeCountMobile, 300)

    const noDiscount = detail?.discount_price === detail?.price

    if (!detail) {
        return <Skeleton.Input active />
    }

    return (
        <>
            <div className={cls.priceSection}>
                <span className={cls.currentPrice}>
                    {isLoading ? (
                        <Skeleton.Input active size="default" />
                    ) : (
                        <>
                            {(
                                Number(detail?.discount_price) * count
                            ).toLocaleString('ru-RU')}
                             сом
                        </>
                    )}
                </span>
                {!noDiscount && (
                    <>
                        <span className={cls.originalPrice}>
                            {(Number(detail.price) * count).toLocaleString(
                                'ru-RU'
                            )}
                             сом
                        </span>
                        <span className={cls.saved}>
                            экономия:{' '}
                            {(
                                Number(detail.price) * count -
                                Number(detail.discount_price) * count
                            ).toLocaleString('ru-RU')}{' '}
                            сом
                        </span>
                    </>
                )}
            </div>
            {isLoading ? (
                <Skeleton.Input
                    active
                    size="small"
                    className={cls.quantitySelector_skeleton}
                />
            ) : (
                <div className={cls.quantitySelector}>
                    <BrowserView>
                        <InputNumber
                            value={count}
                            onChange={handleChangeCount}
                            max={10000}
                            min={1}
                        />
                    </BrowserView>
                    <MobileView>
                        <Input
                            onChange={debouncedCountChangeMobile}
                            max={10000}
                            type="number"
                            min={1}
                            placeholder="введите количество..."
                        />
                    </MobileView>
                </div>
            )}
        </>
    )
}

{
    /* <div className={cls.quantitySelector}>
<button onClick={handleChangeMinus}>
    <MinusOutlined className={cls.icons} />
</button>
<input type="text" value={count} readOnly max={0} />
<button onClick={handleChangeCount}>
    <PlusOutlined className={cls.icons} />
</button>
</div> */
}
