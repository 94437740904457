import { classNames } from '../../../../shared/lib/classNames/classNames'
import { Detail } from '../../model/types/ProductDetailCardSchema'
import cls from './ProductSelectByColor.module.scss'
import { Space, Select, Typography, Skeleton } from 'antd'
import { BrowserView, MobileView } from 'react-device-detect'
import { ChangeEvent } from 'react'

interface Props {
    detail: Detail[]
    selectedDetail?: Detail
    selectDetail: (detail: Detail) => void
    isLoading?: boolean
}
const { Text } = Typography

export const ProductSelectByColor = (props: Props) => {
    const { detail, selectDetail, selectedDetail, isLoading = true } = props

    const handleSetSelectedDetail = (id: number) => {
        const selectedItem: Detail =
            detail?.find((item) => item.id === id) || ({} as Detail)

        selectDetail(selectedItem)
    }
    const handleChange = (value: string) => {
        handleSetSelectedDetail(Number(value))
    }

    const onMobileSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        handleSetSelectedDetail(Number(e.target.value))
    }

    const browserSelectData = detail?.map((item) => ({
        value: item.id,
        label: item.size
    }))

    if (detail?.length === 0) {
        return <p>нет товаров</p>
    }
    return (
        <Space direction="vertical">
            <Space direction="horizontal">
                {isLoading
                    ? [0, 1, 2, 3, 4].map((i) => (
                          <Skeleton.Avatar size={'small'} key={i} />
                      ))
                    : selectedDetail?.color?.map((item, i) => (
                          <div
                              style={{
                                  backgroundColor: item ? item : '#ffffff'
                              }}
                              className={classNames(cls.item)}
                              key={i}
                          />
                      ))}
            </Space>

            <BrowserView key={selectedDetail?.size}>
                {isLoading ? (
                    <Skeleton.Input active />
                ) : (
                    <Select
                        defaultValue={selectedDetail?.size}
                        style={{ width: 120 }}
                        onChange={handleChange}
                        options={browserSelectData}
                    />
                )}
            </BrowserView>
            <MobileView>
                <Space>
                    <select
                        name="cars"
                        id="cars"
                        className={cls.select}
                        onChange={onMobileSelectChange}
                    >
                        {detail?.map((item, i) => (
                            <option value={item.id} key={i}>
                                {item.size}
                            </option>
                        ))}
                    </select>
                </Space>
            </MobileView>
            {isLoading ? (
                <Skeleton.Input size="small" active />
            ) : (
                <Text mark>Количество товаров: {selectedDetail?.quantity}</Text>
            )}
        </Space>
    )
}
