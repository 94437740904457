import cls from './SectionTitle.module.scss'
import { classNames } from '../../lib/classNames/classNames'

interface Props {
    title: string
    children?: React.ReactNode | string | number
    className?: string
}
export const SectionTitle = (props: Props) => {
    const { children, title, className } = props

    return (
        <div className={classNames(cls.title, {}, [className])}>
            <p>{title}</p>
            {children}
        </div>
    )
}
