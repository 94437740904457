import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../../app/providers/StoreProvider';
import { CategoriesListType, EditProps, PostCategoriesProps, PostCategoriesResponse } from '../types/CategoriesPanelSchema';
import { message } from 'antd';
import { PaginationGlobalType } from '../../../../../shared/types';
import { AxiosResponse } from 'axios';
import api from '../../../../../shared/api/api';
import { CategoriesPanelActions } from '../slices/CategoriesPanelSlice';

export const AddCategoryService = createAsyncThunk<PostCategoriesResponse, PostCategoriesProps, ThunkConfig<string>>(
    'categories/AddCategoryService',
    async (data, thunkApi) => {
        const { extra, dispatch, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.post<PostCategoriesResponse>('/category/', data);
            if (!response.data) {
                throw new Error();
            }
            dispatch(CategoriesPanelActions.setCategoryItem(response.data))
            message.success('Успешно добавлено!')

            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при добавлении!');
        }
    },

)




export const fetchCategoriesListService = createAsyncThunk<CategoriesListType, PaginationGlobalType, ThunkConfig<string>>(
    'categories/fetchCategoriesListService',
    async (data, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<CategoriesListType>('/category/', {
                params: {
                    page: data.page,
                    per_page: data.per_page
                }

            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)

export async function categoryUploadImageService(id: any, data: FormData): Promise<AxiosResponse<any>> {
    return api.post<any>(`/category/${id}/upload_image/`, data)
}
export async function categoryDeleteService(id: any): Promise<AxiosResponse<any>> {
    return api.delete<any>(`/category/${id}/`)
}




export async function categoryEditService(id: any, data: EditProps): Promise<AxiosResponse<any>> {
    return api.put<any>(`/category/${id}/`, data)
}

