import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addProductService, fetchProductsData } from '../services';
import { ProductItemType } from '../..';
import { ProductsDataType } from '../types/Product';
import { ProductsPanelSchema } from '../types/ProductsPanelSchema';

const initialState: ProductsPanelSchema = {
    addProduct: {},
    productsList: {},
    editingProduct: { EditingProduct: {} as ProductItemType, isEditing: false }
};

export const ProductsPanelSlice = createSlice({
    name: 'ProductsPanel',
    initialState,
    reducers: {
        setProductLists: (state, action: PayloadAction<ProductsDataType>) => {
            state.productsList.data = action.payload
        },
        setEditingProductItem: (state, action: PayloadAction<ProductItemType>) => {
            state.editingProduct = { EditingProduct: action.payload, isEditing: true }
        },
        setEditingProductItemEnd: (state) => {
            state.editingProduct = { EditingProduct: {} as ProductItemType, isEditing: false }
        },
    },
    extraReducers: (builder) => {
        builder

            // add product
            .addCase(addProductService.pending, (state) => {
                state.addProduct.error = undefined;
                state.addProduct.isLoading = true;
            })
            .addCase(addProductService.fulfilled, (state) => {
                state.addProduct.isLoading = false;
            })
            .addCase(addProductService.rejected, (state, action) => {
                state.addProduct.error = action.payload;
                state.addProduct.isLoading = false;
            })

            // fetch products
            .addCase(fetchProductsData.pending, (state) => {
                state.productsList.error = undefined;
                state.productsList.isLoading = true;
            })
            .addCase(fetchProductsData.fulfilled, (state, action) => {
                state.productsList.isLoading = false;
                state.productsList.data = action.payload
            })
            .addCase(fetchProductsData.rejected, (state, action) => {
                state.productsList.error = action.payload;
                state.productsList.isLoading = false;
            })
    },
});

export const { actions: ProductsPanelActions } = ProductsPanelSlice;
export const { reducer: ProductsPanelReducer } = ProductsPanelSlice;