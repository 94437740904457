import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkConfig } from "../../../../app/providers/StoreProvider";
import { PaginationGlobalType } from "../../../../shared/types";
import { UserProductsTypeResponse } from "../types/ProductsSchema";
import api from "../../../../shared/api/api";
import { AxiosResponse } from "axios";

export const fetchProductsListRecomendations = createAsyncThunk<UserProductsTypeResponse, PaginationGlobalType, ThunkConfig<string>>(
    'product/fetchProductsListRecomendations',
    async ({ page, per_page }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<UserProductsTypeResponse>('/product/', {
                params: {
                    recommended: true,
                    page: page,
                    per_page: per_page
                }
            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)
export const fetchProductsListRecomendationsLeftSide = createAsyncThunk<UserProductsTypeResponse, PaginationGlobalType, ThunkConfig<string>>(
    'product/fetchProductsListRecomendationsLeftSide',
    async ({ page, per_page }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<UserProductsTypeResponse>('/product/', {
                params: {
                    recommended_left_side: true,
                    page: page,
                    per_page: per_page
                }
            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)
export const fetchProductsListBestsellers = createAsyncThunk<UserProductsTypeResponse, PaginationGlobalType, ThunkConfig<string>>(
    'product/fetchProductsListBestsellers',
    async ({ page, per_page }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<UserProductsTypeResponse>('/product/', {
                params: {
                    bestsellers: true,
                    page: page,
                    per_page: per_page
                }
            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)

export const fetchProductsListPromotionals = createAsyncThunk<UserProductsTypeResponse, PaginationGlobalType, ThunkConfig<string>>(
    'product/fetchProductsListPromotionals',
    async ({ page, per_page }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<UserProductsTypeResponse>('/product/', {
                params: {
                    promotional: true,
                    page: page,
                    per_page: per_page
                }
            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)


interface fetchProductsProps extends PaginationGlobalType {
    categoryId: string | number

}

export const fetchProductsByCategoryId = createAsyncThunk<UserProductsTypeResponse, fetchProductsProps, ThunkConfig<string>>(
    'product/fetchProductsByCategoryId',
    async (data, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<UserProductsTypeResponse>(`/product/`, {
                params: {
                    category: data.categoryId,
                    page: data.page,
                    per_page: data.per_page
                }
            });
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных');
        }
    },
)




export async function fetchProductBestsellersService({ page, per_page }: PaginationGlobalType): Promise<AxiosResponse<UserProductsTypeResponse>> {
    return api.get<UserProductsTypeResponse>('/product/', {
        params: {
            bestsellers: true,
            page: page,
            per_page: per_page
        }
    })
}
