import { classNames } from '../../lib/classNames/classNames'
import cls from './MyText.module.scss'

export enum TextTheme {
    PRIMARY = 'primary'
}

export type TextVariant = 'primary' | 'error' | 'accent' | 'hidden'
export type TextAlign = 'right' | 'left' | 'center'

interface TextProps {
    className?: string
    text?: string
    title?: string
    variant?: TextVariant
    align?: TextAlign
    bold?: boolean
    subtitle?: string
}

export const MyText = (props: TextProps) => {
    const {
        className,
        title,
        subtitle,
        text,
        variant = 'primary',
        align = 'left',
        bold
    } = props

    const additionalClasses = [className, cls[variant], cls[align]]

    return (
        <div
            className={classNames(
                cls.Text,
                { [cls.bold]: bold },
                additionalClasses
            )}
        >
            {text && <p className={cls.text}>{text}</p>}
            {title && <p className={cls.title}>{title}</p>}
            {subtitle && <p className={cls.subtitle}>{subtitle}</p>}
        </div>
    )
}
