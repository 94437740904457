import { memo } from 'react'
import { MyText } from '../../../../shared/ui/MyText/MyText'
import cls from './ProductTitle.module.scss'
import { Skeleton } from 'antd'

interface Props {
    title: string
    codeName: string
    isloading?: boolean
}
export const ProductTitle = memo(
    ({ codeName, title, isloading = true }: Props) => {
        return (
            <div className={cls.header}>
                {isloading ? (
                    <Skeleton.Input active size="large" className={cls.title} />
                ) : (
                    <MyText title={title} bold className={cls.title} />
                )}

                {isloading ? (
                    <span className={cls.id}>
                        <Skeleton.Input size="small" active />
                    </span>
                ) : (
                    <span className={cls.id}>{codeName}</span>
                )}
            </div>
        )
    }
)
