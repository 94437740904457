import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderFooterInfoResponse, HeaderFooterInformationSchema } from '../types/HeaderFooterInformationSchema';
import { fetchHeaderFooterService } from '../services';

const initialState: HeaderFooterInformationSchema = {
    info: {} as HeaderFooterInfoResponse
};

export const HeaderFooterInformationSlice = createSlice({
    name: 'HeaderFooterInformation',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => {

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHeaderFooterService.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchHeaderFooterService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.info = action.payload
            })
            .addCase(fetchHeaderFooterService.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: HeaderFooterInformationActions } = HeaderFooterInformationSlice;
export const { reducer: HeaderFooterInformationReducer } = HeaderFooterInformationSlice;