import { Outlet, RouteProps } from 'react-router-dom'
import { MainPage } from '../../../pages/MainPage/ui/MainPage'
import {
    AppRoutes,
    ChildAppRoutes,
    getRouteAboutPage,
    getRouteAdminAboutPage,
    getRouteAdminCategoriesPage,
    getRouteAdminHeaderInfoPage,
    getRouteAdminPage,
    getRouteAdminProductsPage,
    getRouteAdminReviewsAndTipsPage,
    getRouteAdminWorkConditionsPage,
    getRouteAllCategoriesPage,
    getRouteAllProductsByTypePage,
    getRouteBasketPage,
    getRouteCategoryProductsPage,
    getRouteLoginPage,
    getRouteMainPage,
    getRouteProductDetailPage,
    getRouteReviewsAndTipsPage,
    getRouteWorkConditionsPage
} from '../../const/router'
import { ProductDetailPage } from '../../../pages/ProductDetailPage'

import { UserRoles } from '../../const/types'
import { MainWrapperPage } from '../../../pages/MainWrapperPage'
import { AdminPanelPage } from '../../../pages/AdminPanelPage'
import { LoginPage } from '../../../pages/LoginPage'
import { CategoryProductsPage } from '../../../pages/CategoriesPage'
import { AllCategoriesPage } from '../../../pages/AllCategoriesPage'
import { AllProductsByType } from '../../../pages/AllProductsByType'
import { ReviewsAndTipsPage } from '../../../pages/ReviewsAndTipsPage'
import { WorkConditionsPage } from '../../../pages/WorkConditionsPage'
import { AboutPage } from '../../../pages/AboutPage'
import { BasketPage } from '../../../pages/BasketPage'
import { Suspense, lazy } from 'react'
import { Loader } from '../../ui/Loader/Loader'
import { AdminCategoriesPage } from '../../../pages/AdminCategoriesPage'
import { AdminProductsPage } from '../../../pages/AdminProductsPage'

import { AdminWorkConditionsPage } from '../../../pages/AdminWorkConditionsPage'
import { AdminAboutPage } from '../../../pages/AdminAboutPage'
import { AdminReviewsAndTipsPage } from '../../../pages/AdminReviewsAndTipsPage'

const AddHeaderInfo = lazy(
    () =>
        import(
            '../../../entities/HeaderFooterInformation/ui/AddHeaderInfo/AddHeaderInfo'
        )
)

export type AppRoutesProps = RouteProps & {
    authOnly?: boolean
    child?: Record<string | ChildAppRoutes, AppRoutesProps>
    roles?: UserRoles[]
}

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
    [AppRoutes.MAIN]: {
        path: '/',
        element: <MainWrapperPage />,
        child: {
            [ChildAppRoutes.MAIN_PAGE]: {
                path: getRouteMainPage(),
                element: <MainPage />
            },
            [ChildAppRoutes.PRODUCT_DETAIL]: {
                path: getRouteProductDetailPage(':productId'),
                element: <ProductDetailPage />
            },
            [ChildAppRoutes.CATEGORY_DETAIL]: {
                path: getRouteCategoryProductsPage(':categoryId'),
                element: <CategoryProductsPage />
            },
            [ChildAppRoutes.ALL_CATEGORIEs]: {
                path: getRouteAllCategoriesPage(),
                element: <AllCategoriesPage />
            },
            [ChildAppRoutes.ALL_PRODUCT_BY_TYPE]: {
                path: getRouteAllProductsByTypePage(':type'),
                element: <AllProductsByType />
            },
            [ChildAppRoutes.REVIEWS_AND_TIPS]: {
                path: getRouteReviewsAndTipsPage(':activeKey'),
                element: <ReviewsAndTipsPage />
            },
            [ChildAppRoutes.WROK_CONDITIONS]: {
                path: getRouteWorkConditionsPage(':activeKey'),
                element: <WorkConditionsPage />
            },
            [ChildAppRoutes.ABOUT]: {
                path: getRouteAboutPage(),
                element: <AboutPage />
            },
            [ChildAppRoutes.BASKET_PAGE]: {
                path: getRouteBasketPage(),
                element: <BasketPage />
            }
        }
    },
    [AppRoutes.ADMIN]: {
        path: '/admin/',
        element: (
            <Suspense fallback={<Loader />}>
                <AdminPanelPage />
            </Suspense>
        ),
        authOnly: true,
        child: {
            [ChildAppRoutes.ADMIN_CATEGORIES]: {
                path: getRouteAdminCategoriesPage(),
                element: <AdminCategoriesPage />
            },
            [ChildAppRoutes.ADMIN_PRODUCTS]: {
                path: getRouteAdminProductsPage(),
                element: <AdminProductsPage />
            },
            [ChildAppRoutes.ADMIN_HEADER_INFO]: {
                path: getRouteAdminHeaderInfoPage(),
                element: <AddHeaderInfo />
            },
            [ChildAppRoutes.ADMIN_REVIEWS_TIPS]: {
                path: getRouteAdminReviewsAndTipsPage(),
                element: <AdminReviewsAndTipsPage />
            },
            [ChildAppRoutes.ADMIN_WORK_CONDITIONS]: {
                path: getRouteAdminWorkConditionsPage(),
                element: <AdminWorkConditionsPage />
            },
            [ChildAppRoutes.ADMIN_ABOUT]: {
                path: getRouteAdminAboutPage(),
                element: <AdminAboutPage />
            }
        }
    },
    [AppRoutes.LOGIN]: {
        path: getRouteLoginPage(),
        element: (
            <Suspense fallback={<Loader />}>
                <LoginPage />
            </Suspense>
        )
    }
}
