import { configureStore } from '@reduxjs/toolkit';
import { StateSchema } from './StateSchema';
import $api from '../../../../shared/api/api';
import { ProductDetailCardReducer } from '../../../../entities/ProductDetailCard';
import { CategoriessReducer } from '../../../../entities/Categories';
import { AuthByUserNameReducer } from '../../../../features/AuthByUserName';
import { CategoriesPanelReducer } from '../../../../features/Admin/CategoriesPanel/model/slices/CategoriesPanelSlice';
import { ProductsPanelReducer } from '../../../../features/Admin/ProductsPanel';
import { ProductsReducer } from '../../../../features/Products';
import { HeaderFooterInformationReducer } from '../../../../entities/HeaderFooterInformation';
import { ReviewsAndTipsReducer } from '../../../../features/ReviewsAndTips';
import { BasketCountReducer } from '../../../../features/BasketCount/model/slices/BasketCountSlice';

export function createReduxStore(initialState?: StateSchema) {

    return configureStore({
        reducer: {
            productDetail: ProductDetailCardReducer,
            categories: CategoriessReducer,
            authByUsername: AuthByUserNameReducer,
            categoriesPanel: CategoriesPanelReducer,
            productsPanel: ProductsPanelReducer,
            products: ProductsReducer,
            hedaerFooterIfno: HeaderFooterInformationReducer,
            reviewsAndTips: ReviewsAndTipsReducer,
            basketCount: BasketCountReducer
        },
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    api: $api,
                }
            },
        })
    });
}


export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch']