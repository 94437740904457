export enum AppRoutes {
    MAIN = 'main',
    ADMIN = 'admin',
    LOGIN = 'login'

}
export enum ChildAppRoutes {
    //public
    MAIN_PAGE = 'MAIN_PAGE',
    PRODUCT_DETAIL = 'PRODUCT_DETAIL',
    CATEGORY_DETAIL = 'CATEGORY_DETAIL',
    LOGIN_PAGE = 'LOGIN_PAGE',
    ALL_CATEGORIEs = 'ALL_CATEGORIEs',
    ALL_PRODUCT_BY_TYPE = 'ALL_PRODUCT_BY_TYPE',
    REVIEWS_AND_TIPS = 'REVIEWS_AND_TIPS',
    WROK_CONDITIONS = 'WROK_CONDITIONS',
    ABOUT = 'ABOUT',
    BASKET_PAGE = 'BASKET_PAGE',



    // admin

    ADMIN_MAIN_PAGE = 'admin_page_page',
    ADMIN_CATEGORIES = 'admin_categories',
    ADMIN_PRODUCTS = 'admin_products',
    ADMIN_HEADER_INFO = 'admin_header',
    ADMIN_REVIEWS_TIPS = 'admin_reviews_tips',
    ADMIN_WORK_CONDITIONS = 'admin_work_conditions',
    ADMIN_ABOUT = 'admin_about'


}




export const getRouteMainPage = () => '/'
export const getRouteProductDetailPage = (productId: string) => `/product/${productId}`
export const getRouteCategoryProductsPage = (categoryId: string) => `/category/${categoryId}`
export const getRouteLoginPage = () => '/login'
export const getRouteAllCategoriesPage = () => '/categories'
export const getRouteAllProductsByTypePage = (type: string) => `/products/${type}`
export const getRouteReviewsAndTipsPage = (activeKey: string) => `/reviews_tips/${activeKey}`
export const getRouteWorkConditionsPage = (activeKey: string) => `/work_conditions_tips/${activeKey}`
export const getRouteAboutPage = () => `/about/`
export const getRouteBasketPage = () => `/basket/`


export const getRouteAdminPage = () => '/admin/'
export const getRouteAdminCategoriesPage = () => '/admin/categories'
export const getRouteAdminProductsPage = () => '/admin/products'
export const getRouteAdminHeaderInfoPage = () => '/admin/header'
export const getRouteAdminReviewsAndTipsPage = () => '/admin/reviewsTips'
export const getRouteAdminWorkConditionsPage = () => '/admin/workConditions'
export const getRouteAdminAboutPage = () => '/admin/about'


