import { memo, useState } from 'react'
import {
    Keyboard,
    Mousewheel,
    Navigation,
    Scrollbar,
    Thumbs
} from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import { ImageType } from '../..'
import { NotFoundImage } from '../../../../shared/ui/NotFoundImage/NotFoundImage'
import cls from './ProductCarousel.module.scss'

import 'swiper/css/keyboard'
import 'swiper/css/mousewheel'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/scss'

interface Props {
    className?: string
    images: ImageType[]
    isLoading?: boolean
}

export const ProductCarousel = memo((props: Props) => {
    const { className, images, isLoading = true } = props
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)

    const handleSlideChange = (swiper: any) => {
        setActiveSlideIndex(swiper.activeIndex)
    }

    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>()

    return (
        <div className={className}>
            <Swiper
                modules={[Thumbs, Mousewheel, Keyboard]}
                keyboard
                mousewheel
                thumbs={{ swiper: thumbsSwiper }}
                slidesPerView={1}
                onSlideChange={handleSlideChange}
            >
                {images?.map((item, i) => (
                    <SwiperSlide key={i} className={cls.swiper_main}>
                        {isLoading ? (
                            <NotFoundImage className={cls.isLoadingImage} />
                        ) : (
                            <img src={item.image} alt="my-item" />
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                spaceBetween={10}
                className={cls.swiper_thumbs_main}
                modules={[Thumbs, Navigation, Scrollbar]}
                scrollbar={{ draggable: true, hide: true }}
                navigation
                onSwiper={setThumbsSwiper}
                slidesPerView={5}
            >
                {images?.map((item, i) => (
                    <SwiperSlide key={i} className={cls.swiper_thumbs}>
                        {isLoading ? (
                            <NotFoundImage className={cls.isLoadingImage} />
                        ) : (
                            <img
                                src={item.resized_image}
                                alt="my item"
                                className={
                                    activeSlideIndex === i ? cls.active : ''
                                }
                            />
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
})
