import cls from './CategoryCard.module.scss'
import { AppLink } from '../../../shared/ui/AppLink/AppLink'
import { getRouteCategoryProductsPage } from '../../../shared/const/router'
import { NotFoundImage } from '../../../shared/ui/NotFoundImage/NotFoundImage'
import { useEffect, useState } from 'react'

interface Props {
    imageUrl: string
    name: string
    price: string
    categoryId: string | number
}

export const CategoryCard = (props: Props) => {
    const { price, imageUrl, name, categoryId } = props

    const [imageLoaded, setImageLoaded] = useState(false)

    useEffect(() => {
        const img = new Image()
        img.onload = () => {
            setImageLoaded(true)
        }
        img.src = imageUrl
    }, [imageUrl])

    return (
        <AppLink to={getRouteCategoryProductsPage(categoryId.toString())}>
            <div className={cls.categoryCard}>
                {!imageLoaded && <NotFoundImage className={cls.productImage} />}

                {imageLoaded && (
                    <img
                        src={imageUrl}
                        alt={'product'}
                        className={cls.productImage}
                    />
                )}

                <div className={cls.productInfo}>
                    <div className={cls.productName}>{name}</div>
                    <div className={cls.productCode}>{price}</div>
                </div>
            </div>
        </AppLink>
    )
}
