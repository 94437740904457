import { createSlice } from '@reduxjs/toolkit';
import { fetchProductDetailService } from '../services';
import { ProductDetailCardSchema, ProductDetailDataResponse } from '../types/ProductDetailCardSchema';

const initialState: ProductDetailCardSchema = {
    productDetail: {} as ProductDetailDataResponse
};

export const ProductDetailCardSlice = createSlice({
    name: 'ProductDetailCard',
    initialState,
    reducers: {
        clearProductDetailData: (state) => {
            state.productDetail = {} as ProductDetailDataResponse
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductDetailService.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
                state.productDetail = {} as ProductDetailDataResponse
            })
            .addCase(fetchProductDetailService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.productDetail = action.payload
            })
            .addCase(fetchProductDetailService.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: ProductDetailCardActions } = ProductDetailCardSlice;
export const { reducer: ProductDetailCardReducer } = ProductDetailCardSlice;