import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import { AuthProps } from '../types/AuthByUserNameSchema';
import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from '../../../../shared/const/localstorage';
import { AuthByUserNameActions } from '../slices/AuthByUserNameSlice';


interface LoginByUsernameProps {
    username: string;
    password: string;
    callBack?: () => void
}


export const loginByUsername = createAsyncThunk<AuthProps, LoginByUsernameProps, ThunkConfig<string>>(
    'login/loginByUsername',
    async (authData, thunkApi) => {
        const { extra, dispatch, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.post<AuthProps>('/auth/user/sign_in/', authData);
            if (!response.data) {
                throw new Error();
            }
            localStorage.setItem(USER_REFRESH_TOKEN_KEY, response.data.refresh);
            localStorage.setItem(USER_TOKEN_KEY, response.data.access)
            dispatch(AuthByUserNameActions.setIsAuth(true));

            if (authData.callBack) {
                authData.callBack()
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при авторизации!');
        } finally {
            dispatch(AuthByUserNameActions.initAuthData())
        }
    },
)


export const fetchRefreshToken = createAsyncThunk<AuthProps, void, ThunkConfig<string>>(
    'user/refreshToken',
    async (_, thunkApi) => {
        const { extra, rejectWithValue, dispatch } = thunkApi
        try {

            const response = await extra.api.post<AuthProps>(`/auth/token/refresh/`, {
                refresh: localStorage.getItem(USER_REFRESH_TOKEN_KEY)
            });
            localStorage.setItem(USER_REFRESH_TOKEN_KEY, response.data.refresh);
            localStorage.setItem(USER_TOKEN_KEY, response.data.access)
            dispatch(AuthByUserNameActions.setIsAuth(true))

            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.message);
        } finally {
            dispatch(AuthByUserNameActions.initAuthData())
        }
    },
);


