import cls from './ProductsPanel.module.scss'
import { memo } from 'react'
import { classNames } from '../../../../../shared/lib/classNames/classNames'

interface ProductsPanelProps {
    className?: string
}

const ProductsPanel = memo((props: ProductsPanelProps) => {
    const { className } = props

    return (
        <div className={classNames(cls.ProductsPanel, {}, [className])}></div>
    )
})

export default ProductsPanel
