import svg from '../images/notFound2.webp'
import { CSSProperties } from 'react'

interface Props {
    style?: CSSProperties
    className?: string
}
export const NotFoundImage = ({ style, className }: Props) => {
    return (
        <img
            src={svg}
            style={style}
            className={className}
            alt="notFoundExample"
        />
    )
}
