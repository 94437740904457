import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthByUserNameSchema } from '../types/AuthByUserNameSchema';
import { USER_TOKEN_KEY, USER_REFRESH_TOKEN_KEY } from '../../../../shared/const/localstorage';
import { loginByUsername } from '../services';

const initialState: AuthByUserNameSchema = {
    username: '',
    password: ''
};

export const AuthByUserNameSlice = createSlice({
    name: 'AuthByUserName',
    initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setIsAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload;
        },
        clearAuthData: (state) => {
            state.password = ''
            state.username = ''
        },

        initAuthData: (state) => {
            state._inited = true
        },
        logout: (state) => {
            state.isAuth = false;
            localStorage.removeItem(USER_TOKEN_KEY);
            localStorage.removeItem(USER_REFRESH_TOKEN_KEY);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginByUsername.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(loginByUsername.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(loginByUsername.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: AuthByUserNameActions } = AuthByUserNameSlice;
export const { reducer: AuthByUserNameReducer } = AuthByUserNameSlice;