import { createSlice } from '@reduxjs/toolkit';
import { EditingReviewsTipsData, EditingReviewsTipsProps, ReviewsAndTipsResponse, ReviewsAndTipsSchema } from '../types/ReviewsAndTipsSchema';
import { fetchReviewsAndTipsData, fetchReviewsAndTipsForEdit, fetchWorkConditionsData, fetchWorkConditionsForEdit } from '../services';

const initialState: ReviewsAndTipsSchema = {
    data: {} as ReviewsAndTipsResponse,
    editingReviewsTipsData: {} as EditingReviewsTipsProps,
    workConditionsData: {} as ReviewsAndTipsResponse,
    editingWorkConditionsData: {} as EditingReviewsTipsProps,
};

export const ReviewsAndTipsSlice = createSlice({
    name: 'ReviewsAndTips',
    initialState,
    reducers: {
        endEditing: (state) => {
            state.editingReviewsTipsData.isEditing = false
            state.editingReviewsTipsData.data = {} as EditingReviewsTipsData
        },
        endEditingWorkConditions: (state) => {
            state.editingWorkConditionsData.isEditing = false
            state.editingWorkConditionsData.data = {} as EditingReviewsTipsData
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReviewsAndTipsData.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchReviewsAndTipsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload
            })
            .addCase(fetchReviewsAndTipsData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })

            // editing item fetch
            .addCase(fetchReviewsAndTipsForEdit.pending, (state) => {
                state.editingReviewsTipsData.isEditing = true
                state.editingReviewsTipsData.isLoading = true
                state.editingReviewsTipsData.error = undefined
            })
            .addCase(fetchReviewsAndTipsForEdit.fulfilled, (state, action) => {
                state.editingReviewsTipsData.data = action.payload
                state.editingReviewsTipsData.isLoading = false
            })
            .addCase(fetchReviewsAndTipsForEdit.rejected, (state, action) => {
                state.editingReviewsTipsData.error = action.payload
                state.editingReviewsTipsData.isLoading = false
            })

            // work conditions
            .addCase(fetchWorkConditionsData.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchWorkConditionsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.workConditionsData = action.payload
            })
            .addCase(fetchWorkConditionsData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })

            // editing item fetch
            .addCase(fetchWorkConditionsForEdit.pending, (state) => {
                state.editingWorkConditionsData.isEditing = true
                state.editingWorkConditionsData.isLoading = true
                state.editingWorkConditionsData.error = undefined
            })
            .addCase(fetchWorkConditionsForEdit.fulfilled, (state, action) => {
                state.editingWorkConditionsData.data = action.payload
                state.editingWorkConditionsData.isLoading = false
            })
            .addCase(fetchWorkConditionsForEdit.rejected, (state, action) => {
                state.editingWorkConditionsData.error = action.payload
                state.editingWorkConditionsData.isLoading = false
            })

    },
});

export const { actions: ReviewsAndTipsActions } = ReviewsAndTipsSlice;
export const { reducer: ReviewsAndTipsReducer } = ReviewsAndTipsSlice;