import cls from './ProductDescription.module.scss'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import parse from 'html-react-parser'
import { memo } from 'react'
import { Skeleton } from 'antd'

interface Props {
    className?: string
    descriptionHtml?: string
    isLoading?: boolean
}
export const ProductDescription = memo(
    ({ className, descriptionHtml, isLoading = true }: Props) => {
        return (
            <div className={classNames(cls.description, {}, [className])}>
                {isLoading ? (
                    <Skeleton.Input active />
                ) : (
                    <>{descriptionHtml && parse(descriptionHtml)}</>
                )}
            </div>
        )
    }
)
