import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductsSchema, ProductsType } from '../types/ProductsSchema';
import { fetchProductsByCategoryId, fetchProductsListBestsellers, fetchProductsListPromotionals, fetchProductsListRecomendations, fetchProductsListRecomendationsLeftSide } from '../services';

const initialState: ProductsSchema = {
    bestsellersProducts: {} as ProductsType,
    promotionalProducts: {} as ProductsType,
    recommendedProducts: {} as ProductsType,
    recommendedLeftSideProducts: {} as ProductsType,
    productByCategoryType: {} as ProductsType,

};

export const ProductsSlice = createSlice({
    name: 'Products',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => {

        },
    },
    extraReducers: (builder) => {
        builder
            //recomendations
            .addCase(fetchProductsListRecomendations.pending, (state) => {
                state.recommendedProducts.error = undefined;
                state.recommendedProducts.isLoading = true;
            })
            .addCase(fetchProductsListRecomendations.fulfilled, (state, action) => {
                state.recommendedProducts.isLoading = false;
                state.recommendedProducts.items = action.payload.items
                state.recommendedProducts.total = action.payload.total
            })
            .addCase(fetchProductsListRecomendations.rejected, (state, action) => {
                state.recommendedProducts.error = action.payload;
                state.recommendedProducts.isLoading = false;
            })
            //recomendations leftsize
            .addCase(fetchProductsListRecomendationsLeftSide.pending, (state) => {
                state.recommendedLeftSideProducts.error = undefined;
                state.recommendedLeftSideProducts.isLoading = true;
            })
            .addCase(fetchProductsListRecomendationsLeftSide.fulfilled, (state, action) => {
                state.recommendedLeftSideProducts.isLoading = false;
                state.recommendedLeftSideProducts.items = action.payload.items
                state.recommendedLeftSideProducts.total = action.payload.total
            })
            .addCase(fetchProductsListRecomendationsLeftSide.rejected, (state, action) => {
                state.recommendedLeftSideProducts.error = action.payload;
                state.recommendedLeftSideProducts.isLoading = false;
            })
            //bestsellers
            .addCase(fetchProductsListBestsellers.pending, (state) => {
                state.bestsellersProducts.error = undefined;
                state.bestsellersProducts.isLoading = true;
            })
            .addCase(fetchProductsListBestsellers.fulfilled, (state, action) => {
                state.bestsellersProducts.isLoading = false;
                state.bestsellersProducts.items = action.payload.items
                state.bestsellersProducts.total = action.payload.total
            })
            .addCase(fetchProductsListBestsellers.rejected, (state, action) => {
                state.bestsellersProducts.error = action.payload;
                state.bestsellersProducts.isLoading = false;
            })
            //promotional
            .addCase(fetchProductsListPromotionals.pending, (state) => {
                state.promotionalProducts.error = undefined;
                state.promotionalProducts.isLoading = true;
            })
            .addCase(fetchProductsListPromotionals.fulfilled, (state, action) => {
                state.promotionalProducts.isLoading = false;
                state.promotionalProducts.items = action.payload.items
                state.promotionalProducts.total = action.payload.total
            })
            .addCase(fetchProductsListPromotionals.rejected, (state, action) => {
                state.promotionalProducts.error = action.payload;
                state.promotionalProducts.isLoading = false;
            })
            //productsByCategoryId
            .addCase(fetchProductsByCategoryId.pending, (state) => {
                state.productByCategoryType.error = undefined;
                state.productByCategoryType.isLoading = true;
            })
            .addCase(fetchProductsByCategoryId.fulfilled, (state, action) => {
                state.productByCategoryType.isLoading = false;
                state.productByCategoryType.items = action.payload.items
                state.productByCategoryType.total = action.payload.total
            })
            .addCase(fetchProductsByCategoryId.rejected, (state, action) => {
                state.productByCategoryType.error = action.payload;
                state.productByCategoryType.isLoading = false;
            })
    },
});

export const { actions: ProductsActions } = ProductsSlice;
export const { reducer: ProductsReducer } = ProductsSlice;