import { SectionTitle } from '../../../shared/ui/SectionTitle/SectionTitle'
import { Skeleton } from 'antd'
import { AppLink } from '../../../shared/ui/AppLink/AppLink'
import { useSelector } from 'react-redux'
import { getCategoriessdata } from '../model/selectors/getCategoriessdata'
import CategoriesBlocks from '../../../shared/ui/CategoriesBlocks/CategoriesBlocks'
import { MyText } from '../../../shared/ui/MyText/MyText'
import { getRouteAllCategoriesPage } from '../../../shared/const/router'
import { useMemo } from 'react'

export const Categories = () => {
    const { data, error, isLoading } = useSelector(getCategoriessdata)

    const sortedData = useMemo(() => {
        return data.items
            ? [...data.items].sort(
                  (a, b) => a.right_ordering - b.right_ordering
              )
            : undefined
    }, [data.items])

    const sixElements = useMemo(() => {
        return sortedData?.slice(0, 6)
    }, [sortedData])

    if (isLoading) {
        return <Skeleton active />
    }

    if (error) {
        return <MyText text={error} variant="error" />
    }

    return (
        <section>
            <SectionTitle
                title="Категории"
                children={
                    <AppLink to={getRouteAllCategoriesPage()} underlined>
                        Все категории
                    </AppLink>
                }
            />
            <CategoriesBlocks items={sixElements} />
        </section>
    )
}
