import { Pagination, Row } from 'antd'
import React, { memo, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MyText } from '../../shared/ui/MyText/MyText'
import cls from './MyPagination.module.scss'

type MyPaginationProps = {
    total: number
    onChange: (page: number, pageSize: number) => void
    emptyText?: string
    per_page?: number
}

const MyPagination: React.FC<MyPaginationProps> = memo(
    ({ total, onChange, emptyText = '', per_page }) => {
        const [currentPage, setCurrentPage] = useState(1)
        const [pageSize, setPageSize] = useState(per_page ? per_page : 12)

        const [searchParams, setSearchParams] = useSearchParams()

        const pageFromParams = searchParams.get('page')
        const sizeFromParams = searchParams.get('size')

        const handlePageChange = (page: number, size: number) => {
            searchParams.set('page', page.toString())
            searchParams.set('size', size.toString())
            setCurrentPage(page)
            setPageSize(size)
            onChange(page, size)
            setSearchParams(searchParams)
        }

        const handleChangeShowSize = (size: number) => {
            setPageSize(size)
        }

        const paginationLocale = {
            items_per_page: ''
        }

        useEffect(() => {
            if (pageFromParams && sizeFromParams) {
                setCurrentPage(Number(pageFromParams))
                setPageSize(Number(sizeFromParams))
                onChange(Number(pageFromParams), Number(sizeFromParams))
            } else {
                onChange(currentPage, pageSize)
            }
            // eslint-disable-next-line
        }, [])

        if (!total) {
            return <MyText text={emptyText} />
        }

        return (
            <Row justify={'space-between'} align={'middle'}>
                <Pagination
                    className={cls.pagnination}
                    total={total}
                    current={currentPage}
                    pageSize={pageSize}
                    pageSizeOptions={['9', '12', '15', '18', '30', '60']}
                    onChange={(page, size) => {
                        handlePageChange(page, size)
                    }}
                    showSizeChanger
                    onShowSizeChange={(_, size) => handleChangeShowSize(size)}
                    locale={paginationLocale}
                />
                <p>
                    {'всего элементов'}: {total}
                </p>
            </Row>
        )
    }
)

export default MyPagination
