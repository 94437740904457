import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Popconfirm, Space, Table, message } from 'antd'
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import useApiRequest from '../../../../shared/lib/hooks/useApiRequest'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { MyButton } from '../../../../shared/ui/Button'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import { MyText } from '../../../../shared/ui/MyText/MyText'
import MyPagination from '../../../../widgets/MyPagnination/MyPagination'
import { getReviewsAndTipsdata } from '../../model/selectors/getReviewsAndTipsdata'
import {
    fetchReviewsAndTipsData,
    fetchReviewsAndTipsForEdit,
    removeReviewsAndTypesService
} from '../../model/services'
import { ReviewsAndTipsItem } from '../../model/types/ReviewsAndTipsSchema'
import cls from './ReviewsAndTips.module.scss'

interface ReviewsAndTipsProps {
    className?: string
}

const ReviewsAndTips = memo((props: ReviewsAndTipsProps) => {
    const { className } = props
    const dispatch = useAppDispatch()

    const { data, error, isLoading } = useSelector(getReviewsAndTipsdata)

    const fetchData = useCallback(
        (page: number, per_page: number) => {
            dispatch(
                fetchReviewsAndTipsData({ page: page, per_page: per_page })
            )
        },
        [dispatch]
    )

    const handleDeleteFinish = () => {
        fetchData(1, 100)
    }

    const {
        fetchData: deleteItem
        // isLoading: deleteIsLoading,
        // error: DeleteError,
    } = useApiRequest(
        removeReviewsAndTypesService,
        'Успешно удалено',
        handleDeleteFinish
    )

    const handleEditReviesAndtips = (id: number | string) => {
        message.info('загрузка данных для редактирования...')
        dispatch(fetchReviewsAndTipsForEdit(id))
    }
    const columns = [
        {
            title: '№',
            dataIndex: '№',
            key: 'id',
            render: (_: any, __: any, index: number) => index + 1
        },
        {
            title: 'Название_ru',
            dataIndex: 'title_ru',
            key: 'title_ru'
        },
        {
            title: 'Название_kg',
            dataIndex: 'title_kg',
            key: 'title_kg'
        },
        {
            title: 'Название_en',
            dataIndex: 'title_en',
            key: 'title_en'
        },
        {
            title: 'Очередность',
            dataIndex: 'ordering',
            key: 'ordering'
        },
        {
            title: 'Действия',
            key: 'btns',
            render: (record: ReviewsAndTipsItem) => (
                <Space>
                    <MyButton
                        variant="link"
                        onClick={() => handleEditReviesAndtips(record.id)}
                    >
                        <EditOutlined />
                    </MyButton>
                    <Popconfirm
                        title="Удалить"
                        description={`Вы уверены что хотите удалить: ${record.title_ru}`}
                        onConfirm={() => deleteItem(record.id)}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <>
                            <MyButton variant="link">
                                <DeleteOutlined />
                            </MyButton>
                        </>
                    </Popconfirm>
                </Space>
            )
        }
    ]

    return (
        <div className={classNames(cls.ReviewsAndTips, {}, [className])}>
            {error && <MyText text={error} variant="error" />}
            <Table
                loading={{ spinning: isLoading, indicator: <Loader /> }}
                locale={{ emptyText: 'пусто' }}
                size="small"
                bordered
                pagination={false}
                dataSource={data?.items}
                columns={columns}
                rowKey={'id'}
            />
            <MyPagination
                total={data?.total}
                onChange={fetchData}
                emptyText="Пусто"
            />
        </div>
    )
})

export default ReviewsAndTips
