import { Button, Form, FormProps, Input, InputNumber } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { classNames } from '../../../../../shared/lib/classNames/classNames'
import useApiRequest from '../../../../../shared/lib/hooks/useApiRequest'
import { useAppDispatch } from '../../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { MyText } from '../../../../../shared/ui/MyText/MyText'
import {
    getAddCategoryData,
    getEditingCategoryData
} from '../../model/selectors/getCategoriesPaneldata'
import {
    AddCategoryService,
    categoryEditService,
    fetchCategoriesListService
} from '../../model/services'
import { CategoriesPanelActions } from '../../model/slices/CategoriesPanelSlice'
import { PostCategoriesProps } from '../../model/types/CategoriesPanelSchema'
import cls from './AddCategories.module.scss'

export const AddCategories = () => {
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    const { error, isLoading } = useSelector(getAddCategoryData)

    const { isEditing, editingCategory } = useSelector(getEditingCategoryData)

    const onFinishEditing = () => {
        dispatch(CategoriesPanelActions.setEditingCategoryItemEnd())
        dispatch(
            fetchCategoriesListService({
                page: 1,
                per_page: 100
            })
        )
    }
    const {
        fetchData: EditCategory,
        isLoading: editLoading,
        error: editError
    } = useApiRequest(
        categoryEditService,
        'Успешно измененно!',
        onFinishEditing
    )

    const onFinish: FormProps<PostCategoriesProps>['onFinish'] = (values) => {
        if (isEditing) {
            EditCategory(editingCategory.id, values)
        } else {
            dispatch(AddCategoryService(values))
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            name_ru: editingCategory.name_ru,
            name_kg: editingCategory.name_kg,
            name_en: editingCategory.name_en,
            left_ordering: editingCategory.left_ordering,
            right_ordering: editingCategory.right_ordering
        })

        //eslint-disable-next-line
    }, [editingCategory])

    return (
        <div
            className={classNames(cls.AddCategories, {}, [])}
            key={editingCategory.name_ru}
        >
            {error ||
                (editError && (
                    <MyText text={error || editError} variant="error" />
                ))}
            <Form
                key={editingCategory.name_ru}
                name="Добавление категории"
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <Form.Item<PostCategoriesProps>
                    label="Название"
                    name="name_ru"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<PostCategoriesProps>
                    label="Название_kg"
                    name="name_kg"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<PostCategoriesProps>
                    label="Название_en"
                    name="name_en"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<PostCategoriesProps>
                    label="Левый блок"
                    name="left_ordering"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item<PostCategoriesProps>
                    label="Правый блок"
                    name="right_ordering"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите данные'
                        }
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading || editLoading}
                    >
                        {isEditing ? 'Изменить' : 'Добавить'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
