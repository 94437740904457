import { memo } from 'react'
import { classNames } from '../../../../../shared/lib/classNames/classNames'
import cls from './CategoriesPanel.module.scss'

interface CategoriesPanelProps {
    className?: string
}

const CategoriesPanel = memo((props: CategoriesPanelProps) => {
    const { className } = props

    return (
        <div className={classNames(cls.CategoriesPanel, {}, [className])}></div>
    )
})

export default CategoriesPanel
