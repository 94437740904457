import { useSelector } from 'react-redux'
import { getAuthByUserNamedata } from '../../../../features/AuthByUserName'
import { NotFoundPage } from '../../../../pages/NotFoundPage'
import { UserRoles } from '../../../../shared/const/types'

interface RequireAuthProps {
    children: JSX.Element
    roles?: UserRoles[]
}

export function RequireAuth({ children, roles }: RequireAuthProps) {
    const { isAuth } = useSelector(getAuthByUserNamedata)

    if (!isAuth) {
        return <NotFoundPage />
    }

    return children
}
