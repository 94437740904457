import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddCategoryService, fetchCategoriesListService } from '../services';
import { CategoriesListType, CategoriesPanelSchema, CategoryItem } from '../types/CategoriesPanelSchema';

const initialState: CategoriesPanelSchema = {
    addCategory: {
        error: undefined,
        isLoading: false
    },
    categoriesList: {},
    editingCategory: { editingCategory: {} as CategoryItem, isEditing: false }

};

export const CategoriesPanelSlice = createSlice({
    name: 'CategoriesPanel',
    initialState,
    reducers: {
        setCategoryList: (state, action: PayloadAction<CategoriesListType>) => {
            state.categoriesList.data = action.payload
        },
        setCategoryItem: (state, action: PayloadAction<CategoryItem>) => {
            const data: CategoriesListType = {
                items: state.categoriesList.data?.items || [] as CategoryItem[],
                total: state.categoriesList.data?.total ? state.categoriesList.data.total + 1 : 10
            }
            data.items.push(action.payload)
            state.categoriesList.data = data
        },
        setEditingCategoryItem: (state, action: PayloadAction<CategoryItem>) => {
            state.editingCategory = { editingCategory: action.payload, isEditing: true }
        },
        setEditingCategoryItemEnd: (state) => {
            state.editingCategory = { editingCategory: {} as CategoryItem, isEditing: false }
        },

    },
    extraReducers: (builder) => {
        builder

            // add categories
            .addCase(AddCategoryService.pending, (state) => {
                state.addCategory.error = undefined;
                state.addCategory.isLoading = true;
            })
            .addCase(AddCategoryService.fulfilled, (state, action) => {
                state.addCategory.error = undefined;
                state.addCategory.isLoading = false;
            })
            .addCase(AddCategoryService.rejected, (state, action) => {
                state.addCategory.error = action.payload;
                state.addCategory.isLoading = false;
            })

            // fetech categories
            .addCase(fetchCategoriesListService.pending, (state) => {
                state.categoriesList.error = undefined;
                state.categoriesList.isLoading = true;
                state.categoriesList = {}
            })
            .addCase(fetchCategoriesListService.fulfilled, (state, action) => {
                state.categoriesList.error = undefined;
                state.categoriesList.isLoading = false;
                state.categoriesList.data = action.payload
            })
            .addCase(fetchCategoriesListService.rejected, (state, action) => {
                state.categoriesList.error = action.payload;
                state.categoriesList.isLoading = false;
            })
    },
});

export const { actions: CategoriesPanelActions } = CategoriesPanelSlice;
export const { reducer: CategoriesPanelReducer } = CategoriesPanelSlice;