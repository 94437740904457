
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProductDetailDataResponse } from '../types/ProductDetailCardSchema';

export const fetchProductDetailService = createAsyncThunk<ProductDetailDataResponse, string, ThunkConfig<string>>(
    'product/fetchProductDetailService',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<ProductDetailDataResponse>(`/product/${id}/`);
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении детальных данных товара');
        }
    },
)