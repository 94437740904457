import { ConfigProvider } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    AuthByUserNameActions,
    getAuthByUserNamedata
} from '../features/AuthByUserName'
import { fetchRefreshToken } from '../features/AuthByUserName/model/services'
import { classNames } from '../shared/lib/classNames/classNames'
import { refreshTokenAvailability } from '../shared/lib/helpers/tokenAvailability'
import { useAppDispatch } from '../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { Theme, useTheme } from './providers/ThemeProvider'
import { AppRouter } from './providers/router'

export const App = () => {
    const { theme } = useTheme()

    const isLight = theme === 'app_light_theme'

    const dispatch = useAppDispatch()
    const { _inited } = useSelector(getAuthByUserNamedata)

    useEffect(() => {
        if (!_inited) {
            if (refreshTokenAvailability()) {
                dispatch(fetchRefreshToken())
            } else {
                dispatch(AuthByUserNameActions.initAuthData())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, _inited])

    return (
        <div className={classNames('app', {}, [theme || Theme.LIGHT])}>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: 'SFProDisplay',
                        fontSize: 14,
                        colorBgContainer: isLight ? '#ffffff' : '#646464'
                        // colorText: isLight ? '#1c274c' : '#ffffff'
                    },
                    components: {
                        Carousel: {
                            dotActiveWidth: 20,
                            dotHeight: 10,
                            dotWidth: 10
                        },
                        Badge: {
                            dotSize: 9,
                            colorBorderBg: 'none',
                            marginSM: 4
                        },
                        Table: {
                            borderColor: isLight ? '#f3e2c7' : '#3a3a3a',
                            headerBg: isLight ? '#ffffff' : '#646464',
                            headerColor: isLight ? '#1c274c' : '#ffffff'
                        }
                    }
                }}
            >
                <AppRouter />
            </ConfigProvider>
        </div>
    )
}
