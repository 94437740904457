import { getAddCategoryData, getCategoryList, getEditingCategoryData } from "./model/selectors/getCategoriesPaneldata";
import { AddCategoryService, categoryEditService } from "./model/services";
import { CategoryItem, PostCategoriesProps } from "./model/types/CategoriesPanelSchema";
import { AddCategories } from "./ui/AddCategories/AddCategories";
import CategoriesPanel from "./ui/CategoriesPanel/CategoriesPanel";
export type { CategoriesPanelSchema } from './model/types/CategoriesPanelSchema';

export { CategoriesPanel }

export default AddCategories

//services
export { AddCategoryService, categoryEditService }

// selectors
export { getAddCategoryData, getCategoryList, getEditingCategoryData }


// types
export type { PostCategoriesProps, CategoryItem }
