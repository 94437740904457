import { AxiosResponse } from 'axios'
import api from '../../../../shared/api/api'
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AddHeaderFooterInfoProps, HeaderFooterInfoResponse } from '../types/HeaderFooterInformationSchema';


export const fetchHeaderFooterService = createAsyncThunk<HeaderFooterInfoResponse, void, ThunkConfig<string>>(
    'headerFooter/fetchHeaderFooterService',
    async (_, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<HeaderFooterInfoResponse>('/header_footer/');
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('Ошибка при получении данных!');
        }
    },
)


export async function addHeaderInfo(data: AddHeaderFooterInfoProps): Promise<AxiosResponse<HeaderFooterInfoResponse>> {
    return api.post<HeaderFooterInfoResponse>(`/header_footer/`, data)
}


