import { Suspense, memo, useCallback } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
    AppRoutesProps,
    routeConfig
} from '../../../../shared/config/routeConfig/routeConfig'
import { RequireAuth } from './RequireAuth'

const AppRouter = () => {
    const renderWithWrapper = useCallback((route: AppRoutesProps) => {
        const element = <>{route.element}</>
        return (
            <Route
                key={route.path}
                path={route.path}
                element={
                    route.authOnly ? (
                        <RequireAuth roles={route.roles}>{element}</RequireAuth>
                    ) : (
                        element
                    )
                }
            >
                {route.child &&
                    Object.values(route.child).map((child) => (
                        <Route
                            key={child.path}
                            path={child.path}
                            element={child.element}
                        />
                    ))}
            </Route>
        )
    }, [])

    return <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>
}

export default memo(AppRouter)
