import { Descriptions } from 'antd'
import { memo } from 'react'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import { MyText } from '../../../../shared/ui/MyText/MyText'
import { Characteristics } from '../../model/types/ProductDetailCardSchema'
import cls from './ProductCharacteristics.module.scss'

interface Props {
    className?: string
    data: Characteristics[]
    isLoading?: boolean
}

export const ProductCharacteristics = memo((props: Props) => {
    const { className, data, isLoading = true } = props

    const items = data?.map((item, i) => ({
        key: i,
        label: item.title_ru,
        children: <p>{item.text}</p>
    }))

    return (
        <div className={className}>
            <MyText subtitle="О товаре:" />

            {isLoading ? (
                <Loader />
            ) : (
                <Descriptions
                    bordered
                    size="small"
                    items={items}
                    column={1}
                    className={cls.description}
                    rootClassName={cls.root}
                />
            )}
        </div>
    )
})
