import { Suspense, lazy, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Divider } from 'antd'
import {
    fetchProductsListBestsellers,
    fetchProductsListPromotionals,
    fetchProductsListRecomendations,
    getBestsellersProductsdata,
    getPromotionalProductsdata,
    getRecomendedLeftSideProductsdata,
    getRecomendedProductsdata
} from '../../../features/Products'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { Loader } from '../../../shared/ui/Loader/Loader'
import { fetchProductsListRecomendationsLeftSide } from '../../../features/Products/model/services'

const Recomendation = lazy(() => import('../../../entities/Recomendation'))
const Bestsellers = lazy(() => import('../../../entities/Bestsellers/'))
const PromotionalItems = lazy(
    () => import('../../../entities/PromotionalItems')
)
const Categories = lazy(() => import('../../../entities/Categories'))

export const MainPage = () => {
    const dispatch = useAppDispatch()

    const { total: PromotionalTotal } = useSelector(getPromotionalProductsdata)
    const { total: BestsellersTotal } = useSelector(getBestsellersProductsdata)
    const { total: RecomendedTotal } = useSelector(getRecomendedProductsdata)

    useEffect(() => {
        if (!PromotionalTotal) {
            dispatch(fetchProductsListPromotionals({ page: 1, per_page: 40 }))
        }
        if (!BestsellersTotal) {
            dispatch(fetchProductsListBestsellers({ page: 1, per_page: 9 }))
        }
        if (!RecomendedTotal) {
            dispatch(fetchProductsListRecomendations({ page: 1, per_page: 40 }))
        }

        //eslint-disable-next-line
    }, [dispatch])
    return (
        <Suspense fallback={<Loader />}>
            <Categories />
            <Recomendation />
            <Divider />
            <PromotionalItems />
            <Divider />
            <Bestsellers />
        </Suspense>
    )
}
