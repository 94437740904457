import { Link } from 'react-router-dom'
import cls from './AppLink.module.scss'
import { Mods, classNames } from '../../lib/classNames/classNames'

interface Props {
    to: string
    children: React.ReactNode | string | number | React.ReactElement
    className?: string
    underlined?: boolean
    defaultColor?: boolean
}

export const AppLink = (props: Props) => {
    const { to, children, className, underlined, defaultColor } = props

    const mods: Mods = {
        [cls.underline]: underlined,
        [cls.defaultColor]: defaultColor
    }

    return (
        <Link to={to} className={classNames(cls.appLink, mods, [className])}>
            {children}
        </Link>
    )
}
