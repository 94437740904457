import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCategoriesData } from '../services';
import { CategoriesData, CategoriessSchema } from '../types/CategoriessSchema';


const initialState: CategoriessSchema = {
    data: {} as CategoriesData
};

export const CategoriessSlice = createSlice({
    name: 'Categoriess',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => {

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoriesData.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchCategoriesData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload
            })
            .addCase(fetchCategoriesData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload
            });
    },
});

export const { actions: CategoriessActions } = CategoriessSlice;
export const { reducer: CategoriessReducer } = CategoriessSlice;