import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Col, Input, Row, Space, Typography, message } from 'antd'
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { MyButton } from '../../../../shared/ui/Button'
import { MyText } from '../../../../shared/ui/MyText/MyText'
import logo from '../../../../shared/ui/images/Munira_logo.png'
import { getAuthByUserNamedata } from '../../model/selectors/getAuthByUserNamedata'
import { loginByUsername } from '../../model/services'
import { AuthByUserNameActions } from '../../model/slices/AuthByUserNameSlice'
import cls from './AuthByUserName.module.scss'

interface AuthByUserNameProps {
    className?: string
}

const { Text } = Typography
const AuthByUserName = memo((props: AuthByUserNameProps) => {
    const { className } = props

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { username, password, isLoading, error } = useSelector(
        getAuthByUserNamedata
    )

    const onChangeUsername = useCallback(
        (value: string) => {
            dispatch(AuthByUserNameActions.setUsername(value))
        },
        [dispatch]
    )

    const onChangePassword = useCallback(
        (value: string) => {
            dispatch(AuthByUserNameActions.setPassword(value))
        },
        [dispatch]
    )

    const onSuccesAuth = () => {
        message.success('Успешно авторизованы!')
        navigate('/admin/categories')
        dispatch(AuthByUserNameActions.clearAuthData())
    }

    const onLoginClick = useCallback(() => {
        dispatch(
            loginByUsername({
                username,
                password,
                callBack: onSuccesAuth
            })
        )
        //eslint-disable-next-line
    }, [dispatch, username, password])

    return (
        <div className={classNames(cls.AuthByUserName, {}, [className])}>
            <Row className={cls.form} justify={'center'}>
                <Col xxl={5} xl={7} lg={8} md={10} sm={16} xs={23}>
                    <div className={cls.card}>
                        <Space
                            direction="vertical"
                            style={{ width: '100%', gap: '10px' }}
                        >
                            <Space
                                direction="horizontal"
                                rootClassName={cls.header}
                            >
                                <img
                                    src={logo}
                                    alt="logo"
                                    width={100}
                                    height={'auto'}
                                />
                            </Space>
                            <Text className={cls.title}>{'Логин'}</Text>
                            <Input
                                disabled={isLoading}
                                className={cls.input}
                                placeholder={'Логин'}
                                value={username}
                                onChange={(e) =>
                                    onChangeUsername(e.target.value)
                                }
                                onPressEnter={onLoginClick}
                            />
                            <Text className={cls.title}>{'Пароль'}</Text>
                            <Input.Password
                                disabled={isLoading}
                                placeholder={'Пароль'}
                                onChange={(e) =>
                                    onChangePassword(e.target.value)
                                }
                                className={cls.input}
                                iconRender={(visible) =>
                                    visible ? (
                                        <EyeTwoTone />
                                    ) : (
                                        <EyeInvisibleOutlined />
                                    )
                                }
                                onPressEnter={onLoginClick}
                            />
                            <Space
                                direction="horizontal"
                                style={{
                                    display: 'flex',
                                    justifyContent: error
                                        ? 'space-between'
                                        : 'flex-end',
                                    width: '100%'
                                }}
                            >
                                {error && (
                                    <MyText variant="error" text={error} />
                                )}

                                <MyButton
                                    disabled={isLoading}
                                    loading={isLoading}
                                    variant="outline"
                                    style={{
                                        float: 'right'
                                    }}
                                    onClick={onLoginClick}
                                >
                                    Войти
                                </MyButton>
                            </Space>
                        </Space>
                    </div>
                </Col>
            </Row>
        </div>
    )
})

export default AuthByUserName
